export const VENDOR_ENTITY = 'Vendor';
export const CATEGORY_ENTITY = 'Category';
export const PATRON_ACC_ENTITY = 'Patron account';
export const HEALER_ENTITY = 'Healer';
export const PRODUCT_ENTITY = 'Product';
export const SUBSCRIPTION_ENTITY = 'Subscription';
export const USER_BANK_ACCOUNT_ENTITY = 'User bank account';
export const CONV_TEMPLATE_ENTITY = 'Conversation template';
export const CONV_TEMPLATE_GROUP_ENTITY = 'Conversation template group';
export const CONV_TEMPLATE_BLOCK_ENTITY = 'Conversation template block';
export const CONV_TEMPLATE_ANSWER_ENTITY = 'Conversation template answer';
export const THREAT_DEFINITION_ENTITY = 'Threat definition';
export const THREAT_MUTATION_ENTITY = 'Threat mutation';
export const TRANSITION_TYPE = 'Transition type';
export const THREAT_TYPE = 'Threat';
export const TRANSACTION_MAPPING_ENTITY = 'Transaction mapping';
export const PAYMENT_MAPPING_ENTITY = 'Payment mapping';
export const MUNICIPALITY_ENTITY = 'Municipality';
export const REWARD_ENTITY = 'Reward';
export const THREAT_MUTATION_PLACEHOLDER = 'Threat mutation placeholder';
export const CONVERSATION_MESSAGE_PLACEHOLDER = 'Conversation message placeholder';
export const THREAT_MUTATION_VIEW = 'Threat mutation views';
export const BACKEND_CACHE = 'Backend cache';
export const HEALING_GROUP = 'Healing group';
export const HEALER_REGIONS = 'Healer\'s regions';
export const HEALER_GROUPS = 'Healer\'s healing groups';
export const BANNER = 'Banner';
export const WALLET_WITHDRAWAL_REQUEST = 'Wallet withdrawal request';
export const HEALERS_MASTER_GROUP = 'Healers master group';
export const HEALERS_SUBSIDIARY_GROUP = 'Healers subsidiary group';
export const BANNED_INPUTS = 'Banned inputs';
export const NOTIFICAIONT_DEFINITION = 'Notification definition';
export const NOTIFICAIONT_DEFINITION_VIEWS = 'Notification definition views';
export const SMS_DEFINITION = 'SMS definition';
export const SMS_DEFINITION_VIEWS = 'SMS definition views';
export const SCORING_CALCULATION_REQUEST = 'Scoring calculation request';
export const THREAT_MUTATION_TESTING_DATASET = 'Threat mutation testing dataset';
export const PARTNER = 'Partner';
export const THREAT_BULK_SEARCH_ENTITY = 'Threat bulk search';
export const THREAT_VISBILITY_CALCULATION_ENTITY = 'Threat visibility calculation';
export const CASH_REWARD_DEFINITION = 'Cash reward definition';
export const USER_SCAN = 'User scan';
export const CONVERSATION_BLOCK_PAGE_DEFINITION = 'Conversation block CTA page definition';
export const THREAT_MUATION_TAG = 'Threat mutation tag';
export const TRANSLATION = 'Translations';
export const WALLET_BALANCE_AUDIT = 'Wallet balance audit';
export const NO_CODE_AUDIENCE_VIEW_DEFINITIONS = 'No code audience view definitions';
export const NO_CODE_SPECIFICATION_VIEW_DEFINITIONS = 'No code specification view definitions';
export const SMART_EMAILING_PROCESS_LOG = 'Smart emailing process log';
export const SMART_EMAILING_IMPORT_REQUEST = 'Smart emailing bulk import request';
export const IMPORT_EXPORT_PROCESS = 'Import data process';
export const PDF_REPORTS_TEMPLATE = 'PDF report templates'
export const REWARD_ORDER = 'Reward orders';
export const USER_DELETION_REQUESTS = 'User deletion requests';
export const CUSTOM_ACTION_LOGS = 'Custom action logs';
export const CUSTOM_ACTION = 'Custom action';

export enum ErrorMethod {
  SAVE = 'Saving of {0} failed.',
  DELETE = 'Deleting of {0} failed.',
  LIST = 'Getting list of {0} failed.',
  DETAIL = 'Getting detail of {0} failed.',
  ACTIVATION = 'Activation of {0} failed.',
  DEACTIVATION = 'Deactivation of {0} failed.',
  VALIDATION = 'Validation of {0} failed.',
  UPLOAD = 'Upload of {0} to firebase storage failed.',
  CLEAR = 'Clearing of {0} failed.',
  RESET = 'Resetting of {0} failed',
  DUPLICATE = 'Duplication of {0} failed',
  ORDER = 'Ordering of {0} failed.',
  TRIGGER = 'Triggering of {0} failed.',
  ARCHIVING = 'Archiving process of {0} failed.',
  VERIFY = 'Verifying process of {0} failed.',
  CREATE = 'Creating of {0} failed.',
  IMPORT = 'Importing of {0} failed.',
  EXPORT = 'Exporting of {0} failed'
}

export enum SuccessMethod {
  CREATE = '{0} was successfully created.',
  ORDER = '{0} were successfully ordered.',
  MODIFY = '{0} was successfully modified.',
  DELETE = '{0} was successfully deleted.',
  LIST = '{0} list was successfully fetched.',
  ACTIVATION = '{0} was successfully activated.',
  DEACTIVATION = '{0} was successfully deactivated.',
  VALIDATION = '{0} was successfully validated.',
  UPLOAD = '{0} was successfully uploaded to firebase storage.',
  CLEAR = '{0} was successfully cleared.',
  RESET = '{0} was successfully reset.',
  DUPLICATE = '{0} was successfully duplicated.',
  TRIGGER = '{0} was successfully triggered.',
  ARCHIVE = '{0} was successfully archived.',
  UNARCHIVE = '{0} was successfully unarchived.',
  VERIFY = '{0} was successfully verified.',
  FINISH = '{0} was successfully finished'
}
